import styled from '@emotion/styled'
import { PaletteColors, ViewPorts } from 'assets/theme'
import BlackLogoImgUrl from 'assets/images/logo_playu_black.png'
import WhiteLogoImgUrl from 'assets/images/logo_playu_white.png'
import { css, SerializedStyles } from '@emotion/react'
import { TemplateSettings } from 'utils/template'
import { scaleAnimation } from './styles.animations'

interface SCContainerProps extends TemplateSettings {
  customLogo?: string
  stopAnimation: boolean
}

export const CSSLogo = ({ palette, customLogo }: SCContainerProps): SerializedStyles => {
  let url = customLogo
  let width = '45px'
  let height = '45px'

  if (!customLogo) {
    width = '27px'
    height = '30px'
    url = PaletteColors.white === palette?.playuButton?.backgroundColor ? BlackLogoImgUrl : WhiteLogoImgUrl
  }

  const backhgroundStyles = `
    width: ${width};
    height: ${height};
    background-image: url(${customLogo});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  `

  return css`
    .logo {
      width: 42px;
      height: auto;
      color: ${PaletteColors.white};

      svg {
        margin-top: -3px;
        fill: ${PaletteColors.white};
      }
    }
  `
}

export const SCContainer = styled.div<SCContainerProps>`
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 15px;
  right: 15px;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 24%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  animation: ${({ stopAnimation }) =>
    stopAnimation
      ? 'none'
      : css`
          ${scaleAnimation} 5s infinite ease-out
        `};

  ${({ palette }) => {
    return `background-color: ${palette?.playuButton?.backgroundColor || PaletteColors.black};`
  }}

  @media (min-width: ${ViewPorts.mdMin}) {
    bottom: 40px;
    right: 36px;
  }

  ${props => CSSLogo(props)}
`
