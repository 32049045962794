import React from 'react'
import { BusinessProfileType, PremiumTemplates, SocialMedia } from 'utils/constants'
import { PremiumUser, PremiumUserArgs } from 'models/premium-user'
import LayoutContainer from 'components/templates/premium/base-two/Layout'
import AvatarImg from 'assets/images/premium/p-03/avatar.png'
import { toContentfulRaw } from 'utils/format'

const defaultData: PremiumUserArgs = {
  isDemo: true,
  id: '1',
  name: 'Raúl',
  firstLastName: 'Mendoza',
  nameToDisplay: 'Raúl Mendoza',
  avatarUrl: {
    file: {
      url: AvatarImg,
    },
  },
  businessProfileType: BusinessProfileType.Personal,
  template: PremiumTemplates.BaseTwo_01,
  metadata: {
    pageTitle: 'Professional P03',
  },
  jobs: [
    {
      id: '1',
      title: ' Product Manager',
    },
  ],
  contact: {
    id: '1',
    phoneNumbers: ['+51901422305'],
    email: 'hola@playu.pe',
    addresses: [
      {
        id: '1',
        description: {
          description: 'Lima, Peru',
        },
      },
    ],
    socialMedia: [
      {
        id: '1',
        order: 1,
        type: SocialMedia.MailGeneric,
        link: 'mailto:hola@playu.pe',
      },
      {
        id: '2',
        order: 2,
        type: SocialMedia.Facebook,
        link: 'https://www.facebook.com/playu.shop',
      },
      {
        id: '3',
        order: 3,
        type: SocialMedia.Instagram,
        link: 'https://instagram.com/playu.shop',
      },
      {
        id: '4',
        order: 4,
        type: SocialMedia.Whatsapp,
        link: 'https://api.whatsapp.com/send?phone=51901422305&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20PlayU',
      },
      {
        id: '5',
        order: 5,
        type: SocialMedia.Youtube,
        link: 'https://www.youtube.com/channel/UCne87oRuqn9eEIZQ63A5EQg',
      },
      {
        id: '6',
        order: 6,
        type: SocialMedia.Twitter,
        skip: true,
        link: '#',
      },
    ],
  },
  services: [
    {
      id: '1',
      icon: 'ri-lightbulb-flash-fill',
      title: 'Coaching Profesional',
      description: {
        raw: toContentfulRaw(
          'Ayudo a mejorar tu marca personal, encontrar el trabajo de tus sueños y a triunfar en tus objetivos profesionales.'
        ),
      },
    },
    {
      id: '2',
      icon: 'ri-window-2-fill',
      title: 'Reinserción Laboral',
      description: {
        raw: toContentfulRaw(`Estaré contigo en todo tu proceso de Reinserción Laboral.
      Trabajamos sobre tu objetivo profesional, marca personal,
      comunicación, curriculum, linkedin, oportunidades de empleo y
      procesos de selección, entre otras muchas cosas.`),
      },
    },
    {
      id: '3',
      icon: 'ri-award-fill',
      title: 'Ponencia sobre Personal Branding',
      description: {
        raw: toContentfulRaw(`Enseño a ambos (candidatos y empresas) una nueva estrategia
      que de verdad funcione. Acciones con las que conectar
      candidato y reclutador..`),
      },
    },
  ],
}

const PageComponent: React.FC = () => (
  <LayoutContainer user={new PremiumUser(defaultData)} template={PremiumTemplates.BaseTwo_01} />
)

export default PageComponent
