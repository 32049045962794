import React, { useContext, useState } from 'react'
import { BaseContext } from 'shared/context/BaseContext'
import SaveIcon from 'assets/images/add-user-icon-2.svg'
import { SCContainer } from './styles'

interface PlayuButtonProps {
  customLogo?: string
}

const PlayuButton: React.FC<PlayuButtonProps> = ({ customLogo }: PlayuButtonProps) => {
  const { setShowShareDataModal, settings } = useContext(BaseContext)
  const [stopAnimation, setStopAnimation] = useState(false)

  const handleLogoClicked = (): void => {
    if (!stopAnimation) {
      setStopAnimation(true)
    }
    setShowShareDataModal({ show: true })
  }

  return (
    <SCContainer
      stopAnimation={stopAnimation}
      onClick={handleLogoClicked}
      role="button"
      customLogo={customLogo}
      palette={settings?.palette}
    >
      <div className="logo" role="button">
        <img src={SaveIcon} alt="playu-button" />
      </div>
    </SCContainer>
  )
}

export default PlayuButton
