import { keyframes } from '@emotion/react'

export const shakeHorizontalAnimation = keyframes`
  39%,
  61% {
    transform: translate3d(0, 0, 0);
  }

  40%,
  44%,
  48%,
  52%,
  56% {
    transform: translate3d(-5px, 0, 0);
  }

  42%,
  46%,
  50%,
  54% {
    transform: translate3d(5px, 0, 0);
  }
`

export const scaleAnimation = keyframes`
40% {
  transform: scale(1);
}

45% {
  transform: scale(1.25);
}

50% {
  transform: scale(1);
}

55% {
  transform: scale(1.25);
}

60% {
  transform: scale(1);
}
`
